import fetch from 'auth/FetchInterceptor';
import {API_BASE_URL} from "../configs/AppConfig";

const CompanyService = {}

CompanyService.myTenant = function (params) {
  return fetch({
    url: '/api/tenants/me',
    baseURL: API_BASE_URL,
    method: 'get',
    params
  })
}

CompanyService.create = function (data) {
  return fetch({
    url: '/api/tenants/me-create',
    baseURL: API_BASE_URL,
    method: 'post',
    data
  })
}
CompanyService.update = function (id,data) {
  return fetch({
    url: `/tenants/me-update`,
    method: 'put',
    data
  })
}
CompanyService.setupComplete = function (data) {
  return fetch({
    url: '/tenants/setup-complete',
    method: 'post',
    data
  })
}

CompanyService.findDomains = function () {
  return fetch({
    url: '/company/domain',
    method: 'get',
  })
}



export default CompanyService

import React, {useEffect} from 'react';
import {Col, DatePicker, Form, Input, Row} from 'antd';
import IntlMessage from "../../components/util-components/IntlMessage";
import {useDispatch, useSelector} from "react-redux";
import {handleSetupCompany} from "../../redux/actions/company";

const WinrarStepOne = ({formRef}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.auth.user_info);
    const {currentStep, data: company} = useSelector((state) => state.company);

    const onFinish = async (values) => {
        try {
            console.log('Submitted values: ', values);
            dispatch(handleSetupCompany.success({
                data: {...company, ...values},
                currentStep: currentStep + 1,
                isDisabledNext: true,
                isDisabledPrev: false
            }))
        } catch (err) {
            console.error("EX ", err);
        }

    };

    useEffect(() => {
        dispatch(handleSetupCompany.success({isDisabledNext: false, isDisabledPrev: false}))
        form.setFieldsValue({
            tenantMsid: userInfo.tenantId,
            tenantName: userInfo.tenantInfo?.displayName,
            tenantEmail: userInfo.mail,
            phone: userInfo.mobilePhone,
            ...company,
        });

    }, [userInfo, company]);

    useEffect(() => {
        formRef.current = form;
    }, [form]);


    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 24},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 22},
        },
    };
    return (
        <Form
            {...formItemLayout}
            form={form}
            layout="vertical"
            name="winrarStepOne"
            onFinish={onFinish}
            style={{
                padding: 50,
                // maxWidth: 600,
                margin: 'auto'
            }}
        >
            <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        name="tenantName"
                        label={<IntlMessage id="team.winrar.tenantName"/>}
                        rules={[{required: true, message: 'Please input the tenant name!'}]}
                    >
                        <Input style={{width: '100%'}}/>
                    </Form.Item>

                    <Form.Item
                        name="tenantEmail"
                        label="Email"
                        rules={[{required: true, message: 'Please input the tenant email!'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="phone"
                        label="Phone"
                        // rules={[{required: true, message: 'Please input the phone number!'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="tel"
                        label="Tel"
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name="tax"
                        label="Tax"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="address"
                        label="Address"
                        // rules={[{required: true, message: 'Please input the address!'}]}
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col span={12}>

                    <Form.Item
                        name="dateOfEstablishment"
                        label={<IntlMessage id="team.winrar.dateOfEstablishment"/>}

                    >
                        <DatePicker className="w-100"/>
                    </Form.Item>
                    <Form.Item
                        name="businessRegistrationCode"
                        label={<IntlMessage id="team.winrar.businessRegistrationCode"/>}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="dateOfIssue"
                        label={<IntlMessage id="team.winrar.dateOfIssue"/>}

                    >
                        <DatePicker className="w-100"/>
                    </Form.Item>
                    <Form.Item
                        name="placeOfIssue"
                        label={<IntlMessage id="team.winrar.placeOfIssue"/>}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="fax"
                        label="Fax"
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="website"
                        label="Website"
                    >
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default WinrarStepOne;

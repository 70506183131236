import _, {find, isEmpty, map} from 'lodash';
import dayjs from 'dayjs';
import {STATUS_WORK} from './constants';
import {InputNumber, Mentions, notification} from "antd";
import {AlertOutlined} from "@ant-design/icons";
import React from "react";
import Utils from "./index";
import moment from "moment";
import * as microsoftTeams from "@microsoft/teams-js";
import store from "../redux/store";
import {resetAuth} from "../redux/actions/Auth";

const {Option} = Mentions;

export const renderDate = (date) =>
    date ? dayjs(date).format('DD/MM/YYYY') : '';
export const delay = (time) => new Promise(resolve => setTimeout(resolve, time));


export const renderMonth = (date) =>
    date ? dayjs(date).format('MM/YYYY') : '';

export const renderDateTime = (date) =>
    date ? dayjs(date).format('DD/MM/YYYY HH:mm:ss') : '';

export const getLabelName = (array, value) => {
    const item = find(array, (i) => i.value === value);
    if (item) return item.label;
    return value;
};

export const optionsStatusWork = () => {
    const result = map(STATUS_WORK, (label, value) => ({value, label}));
    return result;
};

export const dividend = (numerator, denominator) => {
    if (isNaN(Number(denominator)) || isNaN(Number(numerator)) || Number(denominator) <= 0) return 0
    return Number(numerator) / Number(denominator);
}
export const StrLimit = (str, n) => {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
};
export const formatCurrency = (price, removeSymbol = true, currency = 'VND', locale = 'vi-VN') => {
    const formattedPrice = (+price).toLocaleString(locale, {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    // You can create a mapping of currency symbols to replace
    const currencySymbols = {
        'VND': '₫',
        // Add more symbols if needed
    };

    const symbolToReplace = currencySymbols[currency];

    // Replace the currency symbol if it exists in the mapping
    if (symbolToReplace && removeSymbol) {
        return formattedPrice.replace(symbolToReplace, '').trim();
    }

    return formattedPrice;

    // console.log(formatCurrency(1000000)); // Default: VND and vi-VN
    // console.log(formatCurrency(1000000, 'USD', 'en-US')); // USD and en-US
    // console.log(formatCurrency(1000000, 'EUR', 'de-DE')); // EUR and de-DE

};


export const isNotEmptyObject = (obj) => {
    if (obj === null || obj === false || typeof obj === 'undefined') {
        return false;
    }

    if (typeof obj === 'object') {
        return !isEmpty(obj);
    }

    return obj !== '';
};

export const isContainArray = (arr, arg) => {
    if (new RegExp(arr.join("|")).test(arg)) {
        return true;
    } else {
        return false;
    }
};

export const findDeep = (arr, arrIdDepartment) => {
    const mapCustom = (o) => {
        if (o.children.length) {
            arrIdDepartment.push(o.id)
            return o.children.forEach(mapCustom)
        } else {
            arrIdDepartment.push(o.id)
        }
    }
    isNotEmptyObject(arr?.children) && arr.children.forEach(mapCustom)
    return arrIdDepartment
}

export const findChildById = (id, arr) => {
    const result = arr.find(o => o.id === id)
    if (result) return result
    for (const cm of arr) {
        const result = cm.children.find(o => o.id === id)
        if (result) return result
    }
}

export const filterObject = (obj, callback) => {
    return Object.fromEntries(Object.entries(obj).filter(([key, val]) => callback(val, key)));
}

export const formatDateToServer = (date) => {
    if (!date) return;
    const newDate = dayjs(date).format('YYYY-MM-DD');
    return newDate;
};

export const formatMonthToServer = (date) => {
    if (!date) return;
    const newDate = dayjs(date, 'MM/YYYY').format('YYYY-MM');
    return newDate;
};

export const formatDate = (date) => {
    if (!date) return;
    if (dayjs(date, 'DD/MM/YYYY').isValid()) {
        return formatDateToServer(date);
    }
};

export const getNameUser = (array, msid) => {
    const item = find(array, (i) => i.msid === msid);
    if (item) return item.fullname;
    return '';
};

export const getNameUserById = (array, id) => {
    const item = find(array, (i) => i.id === id);
    if (item) return item.fullname;
    return '';
};

export const getNameTeam = (array, team) => {
    const item = find(array, (i) => i.msid === team);
    if (item) return item.name;
    return '';
};

export const getNameProject = (array, planId) => {
    const item = find(array, (i) => i.msid === planId);
    return item?.name || '';
};

export const getNameBucket = (array, bucketId) => {
    const item = find(array, (i) => i.msid === bucketId);
    if (item) return item.title;
    return '';
};

export const formatDateToDisplay = (date) => {
    if (!date) return;
    const newDate = dayjs(date).format('DD/MM/YYYY');
    return newDate;
};

export const formatDateTimeToDisplay = (datetime) => {
    if (!datetime) return;
    const newDate = dayjs(datetime).format('DD/MM/YYYY H:m');
    return newDate;
};

export const convertDataDetailTask = (result) => {
    const newData = {
        ...result,
        startDate: dayjs(result.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        endDate: dayjs(result.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),

        involveds: result.involveds && map(result.involveds, (item) => item.id),
    };

    return newData;
};

export const removeParamsUrl = () => {
    return window.history.pushState({}, document.title, window.location.pathname);
}

export const getValuesToUpdateTask = (data) => {
    const newValues = {
        name: data.name,
        assignee: data.assignee,
        timeWork: data.timeWork,
        scope: data.scope,
        involveds: data.involveds,
        reportRecipient: data.reportRecipient,
        team: data.team,
        planId: data.planId,
        bucketId: data.bucketId,
        endDate: data.endDate,
        isScheduled: data.isScheduled,
        scheduledData: data.scheduledData,
        startDate: data.startDate,
        complexity: data.complexity,
        priority: data.priority,
        description: data.description,
    }

    return newValues;
}

export const convertDateToDisplay = (date) => {
    return dayjs(date).format('DD/MM/YYYY');
}

export const inputNumberSC = () => {
    const inputNumberSC = (
        <InputNumber
            placeholder="Nhập giá trị"
            style={{width: '100%'}}
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/(,*)/g, '')}
            min={0}
        />
    );

    return inputNumberSC;
}

export const getOptionValueSC = (value, salaryComposition) => {
    let data;
    if (value === 'Fomula') {
        data = (
            <Mentions
                placeholder="Tự động gợi ý tham số khi gõ phím cách"
                style={{width: '100%'}}
                autoFocus={true}
                prefix=" "
            >
                {
                    salaryComposition.length && salaryComposition.map(
                        (data, index) =>
                            <Option key={index} value={data.code}>
                                <AlertOutlined style={{fontSize: '17px', marginRight: '8px'}}/>
                                {data.name} ({data.code})
                            </Option>
                    )
                }
            </Mentions>
        );
    } else if (value === 'Number') {
        console.log(2);
        data = inputNumberSC();
    }

    return data;
}

export const isReDo = (status, data) => {
    const userLogin = Utils.getUserInfo();

    return userLogin.msid === data.reportRecipient
        && data.status === status
        && dayjs(data.endDate, "DD/MM/YYYY").isAfter(dayjs(data.completeDate))
        && !data.is_paycheck;
}


export const convertOptionsFilter = (result, field1, field2, filed3 = '') => {
    const data = result && _.map(result, _.partialRight(_.pick, [field1, field2, filed3]));

    const keys = {[field1]: 'value', [field2]: 'label', [filed3]: 'other'};

    const newData = _.map(data, o => {
        return _.mapKeys(o, (v, k) => {
            return k in keys ? keys[k] : k;
        });
    });

    return newData;
}
export const convertWorkingType = (type) => {
    switch (type) {
        case "Apprentice":
            return "Thực tập sinh";
        case "Probation":
            return "Nhân viên thử việc";
        case "Official":
            return "Nhân viên chính thức";
        case "Different":
            return "Khác";
        default:
            return "Không xác định";
    }
};

export const convertStatusEmployee = (type) => {
    switch (type) {
        case "Working":
            return "Đang làm việc";
        case "Rest":
            return "Đã nghỉ việc";
        default:
            return "Không xác định";
    }
};


export const openNotification = ({
                                     des = 'Vui lòng thử lại thao tác hoặc liên hệ bộ phận kỹ thuật để được hỗ trợ',
                                     title = 'Thông báo !',
                                     duration = 4.5,
                                     type = 'error',
                                 }) => {

    notification.destroy()
    setTimeout(() => {
        notification[type]({
            message: title || 'Thông báo !',
            description: des || 'Vui lòng thử lại thao tác hoặc liên hệ bộ phận kỹ thuật để được hỗ trợ',
            duration,
        });
    }, 100)

};

export const disabledDateLast = (current) => {
    // Get today's date
    const today = moment().endOf('day');
    // Disable dates after today
    return current && current < today;
}

export const disabledDateFuture = (current) => {
    // Get today's date
    const today = moment().endOf('day');
    // Disable dates after today
    return current && current > today;
}

export const isRealTime = (timeQuery) => timeQuery.clone().startOf('month').isSameOrAfter(moment().startOf('month'));


export async function renewTokenGraph(teamsUserCredential) {
    const tokenSSO = await teamsUserCredential.getSSOToken();
    return tokenSSO
    /*const scopes = ["User.Read"];
    try {
        const token = await teamsUserCredential.getToken(scopes);
        return token
    } catch (e) {
        console.error("renewTokenGraph", e)
        await loginAction(teamsUserCredential, scopes)
    }*/
}

export async function loginAction(teamsUserCredential, scope) {
    try {
        await teamsUserCredential.login(scope);
        // window.location.reload();
    } catch (e) {
        console.log("loginAction", e.code, e);
        if (e.code === "CancelledByUser") {
            console.error("CancelledByUser")
            openNotification({
                des: 'Quá trình đăng nhập đã bị hủy bởi người dùng.',
                title: 'Đăng nhập bị hủy',
                type: 'warning',
            });
        } else if (e.code === "ConsentFailed") {
            console.error("ConsentFailed required")
            openNotification({
                des: 'Không thể mở cửa sổ đăng nhập. Vui lòng kiểm tra cài đặt chặn cửa sổ popup của trình duyệt. Hoặc sử dụng ứng dụng team để có trải nghiệm tốt nhất',
                title: 'Lỗi mở cửa sổ',
                type: 'warning',
            });
        } else{
            console.log("resetAuth.request required")
            store.dispatch(resetAuth.request())
            window.location.reload();
        }

    }
}


export const checkInTeams = () => {
    // Check if the Microsoft Teams library is loaded
    if (!microsoftTeams) {
        return false;
    }

    // Determine if running inside Microsoft Teams based on window properties
    // - Direct comparison of window parent and self to detect iframe context
    // - Check for 'nativeInterface' property for Teams mobile app
    // - Check for specific window names used by Teams
    return (window.parent === window.self && 'nativeInterface' in window) ||
        window.name === "embedded-page-container" ||
        window.name === "extension-tab-frame";
};

import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {useSelector} from "react-redux";
import AppLayout from "layouts/app-layout";
import AppLocale from "lang";
import {IntlProvider} from "react-intl";
import {ConfigProvider} from 'antd';
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH, IS_TEAM, TEAM_PREFIX_PATH} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import Welcome from "../team/Welcome";
import InfoCompany from "../team/InfoCompany";
import AuthViews from "./auth-views";
import InactiveCompany from "../team/InactiveCompany";

export const Views = () => {
    const {locale, direction} = useSelector(state => state.theme);
    const {token} = useSelector(state => state.auth);
    useBodyClass(`dir-${direction}`);
    return (
        <IntlProvider
            locale={AppLocale[locale].locale}
            messages={AppLocale[locale].messages}>
            <ConfigProvider locale={AppLocale[locale].antd} direction={direction}>
                <Routes>
                    <Route path="/" element={<Navigate to={APP_PREFIX_PATH}/>}/>
                    <Route path={TEAM_PREFIX_PATH} element={IS_TEAM ? <Welcome/> : <Navigate to={APP_PREFIX_PATH}/>}/>
                    <Route path={`${TEAM_PREFIX_PATH}/info-company`} element={<InfoCompany/>}/>
                    <Route path={`${TEAM_PREFIX_PATH}/upgrade`} element={<InactiveCompany/>}/>
                    <Route path={`${AUTH_PREFIX_PATH}/*`} element={<AuthViews/>}/>
                    <Route path={`${APP_PREFIX_PATH}/*`} element={
                        !token ?
                            <Navigate to={IS_TEAM ? TEAM_PREFIX_PATH : AUTH_PREFIX_PATH}/> :
                            <AppLayout isAuthenticated={token} direction={direction}/>
                    }/>
                </Routes>
            </ConfigProvider>
        </IntlProvider>
    )
}

export default Views;
